<template>
  <div class="container-fluid">
   
        <b-modal id="modal-password" title="Reset your password">
          <PasswordReset @close="togglePasswordReset()"></PasswordReset>
        </b-modal>

      <div class="row">
        <div class="col-12">
       
        <div style="padding-left:16px; padding-bottom:150px;  padding-top:50px; border-bottom:1px solid #ccc"  id="login">
          <h4 style="color:#fff; margin-left:16px; margin-top:30px; margin-bottom:30px;"><strong>The Steve Francis Foundation</strong></h4>

        <div :class="{ 'signup-form': !showLoginForm }" class="col-sm-12 col-md-4">
          <form @submit.prevent>
            <div class="form-group">
              <label style="color:#fff;" for="exampleInputEmail1">Email address</label>
              <input
                type="email"
                v-model.trim="loginForm.email"
                placeholder="you@email.com"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div class="form-group">
              <label style="color:#fff;" for="exampleInputPassword1">Password</label>
              <input
                type="password"
                v-model.trim="loginForm.password"
                class="form-control"
                id="exampleInputPassword1"
                placeholder="Enter your password."
              />
            </div>

            <button @click="login()" type="submit" class="btn btn-primary">
              Submit
            </button>
          </form>

          <div class="mt-4">
            <b-button variant="light" v-b-modal.modal-password
              >Forgot Password</b-button
            >
          </div>
        </div>
      </div>
     </div>
    </div>
  </div>
</template>

<script>
import PasswordReset from "@/components/PasswordReset";

export default {
  components: {
    PasswordReset,
  },
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
      showLoginForm: true,
      showPasswordReset: false,
    };
  },
  methods: {
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset;
    },
    login() {
      this.$store.dispatch("login", {
        email: this.loginForm.email,
        password: this.loginForm.password,
      });
    },
  },
};
</script>
<style scoped>
#login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #760000;
  background-size: cover;
}
</style>
