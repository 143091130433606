<template>
  <div >
      <div v-if="!showSuccess">
        <form @submit.prevent class="mb-4">
          <label for="exampleInputEmail1">Enter your email to reset your password</label>
            <input
              type="email"
              v-model.trim="email"
              placeholder="you@email.com"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
        </form>
        <p v-if="errorMsg !== ''" class="error">{{ errorMsg }}</p>
        <b-button @click="resetPassword()" variant="outline-primary">Reset</b-button>
  
      </div>
      <p v-else>Success! Check your email for a reset link.</p>
  </div>
</template>

<script>
import { auth } from '@/firebase'

export default {
  data() {
    return {
      email: '',
      showSuccess: false,
      errorMsg: ''
    }
  },
  methods: {
    async resetPassword() {
      this.errorMsg = ''

      try {
        await auth.sendPasswordResetEmail(this.email)
        this.showSuccess = true
      } catch (err) {
        this.errorMsg = err.message
      }
    }
  }
}
</script>
